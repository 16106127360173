<template>
  <div>
    <div class="has-text-right">
      <b-switch v-model="observing">Turn on live stream</b-switch>
      <hr />
    </div>
    <b-table
      ref="table"
      :data="callsArray"
      :mobile-cards="true"
      :default-sort="[table.sort.field, table.sort.direction]"
      :loading="table.loading"
      :detailed="true"
      :show-detail-icon="true"
      backend-sorting
      striped
      class="has-vertical-align-middle"
      detail-key="_id"
      @click="row => $refs.table.toggleDetails(row)"
    >
      <template slot="empty">
        <no-results
          title="No calls"
          message="There are no api calls to show."
          icon="box-open"
        />
      </template>

      <template
        slot-scope="props"
        :has-detailed-visible="typeof props.row.metadata === 'object'"
      >
        <b-table-column label="Status" custom-key="status" width="1">
          <status-label
            :status="`${props.row.statusCode}`"
            :type="getType(props.row)"
          />
        </b-table-column>

        <b-table-column label="Method" custom-key="method" width="1">
          <strong>{{ props.row.method }}</strong>
        </b-table-column>

        <b-table-column label="Endpoint">{{
          props.row.endpoint
        }}</b-table-column>

        <b-table-column label="Date" field="dateCreated" width="140">{{
          $moment(props.row.dateCreated.toDate()).format("DD/MM/YY HH:mm")
        }}</b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <vue-json-pretty
          :deep="2"
          :data="{
            ip: props.row.fromIP,
            status: props.row.statusCode,
            request: tryGetParsedJSON(props.row.requestBody),
            response: tryGetParsedJSON(props.row.responseBody)
          }"
        />
      </template>
    </b-table>

    <load-more
      v-if="!table.complete"
      :loading="table.loading"
      @loadMore="getData(true)"
    />
  </div>
</template>

<script>
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  startAfter
} from "@firebase/firestore";
import VueJsonPretty from "vue-json-pretty";
export default {
  name: "CallsTable",
  components: {
    "vue-json-pretty": VueJsonPretty
  },
  mixins: [require("@mixins/table-with-filtering-and-sorting").default],
  props: {
    tokenRef: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      pageLoading: true,
      observing: false,
      observed: {},
      unobserve: null
    };
  },
  computed: {
    callsArray() {
      const calls = this.$_.toArray(this.observed);
      return this.$_(calls)
        .toArray()
        .concat(this.table.data)
        .orderBy([t => t.dateCreated.toDate()], ["desc"])
        .value();
    },
    lastCall() {
      return this.$_(this.callsArray)
        .orderBy([t => t.dateCreated.toDate()], ["desc"])
        .first();
    }
  },
  watch: {
    observing(val) {
      if (val) {
        this.observeCalls({
          tokenRef: this.tokenRef,
          cursor: this.lastCall
        });
      } else {
        this.unobserveCalls();
      }
    }
  },
  beforeDestroy() {
    this.unobserveCalls();
  },
  methods: {
    observeCalls({ tokenRef, cursor = null }) {
      const ref = query(
        collection(tokenRef, "calls"),
        orderBy(`dateCreated`, `asc`),
        startAfter(cursor ? cursor.dateCreated.toDate() : new Date())
      );
      this.unobserve = onSnapshot(
        ref,
        { includeMetadataChanges: true },
        snapshot => {
          if (!snapshot.metadata.hasPendingWrites) {
            this.$_.each(snapshot.docChanges(), change => {
              const call = change.doc;
              if (change.type === "removed") {
                this.$delete(this.observed, call.id);
              } else {
                //prettier-ignore
                this.$set(
                  this.observed, call.id, this.$_.merge({}, { _id: call.id }, call.data())
                );
              }
            });
          }
        },
        () => {
          this.$toast.open({
            message: "Error fetching logs",
            type: "is-danger"
          });
        }
      );
    },
    unobserveCalls() {
      this.$_.isFunction(this.unobserve) && this.unobserve();
      this.unobserve = null;
    },
    getType(call) {
      if (call.statusCode >= 200 && call.statusCode < 300) return "is-success";
      else if (call.statusCode >= 400 && call.statusCode < 500)
        return "is-warning";
      else if (call.statusCode >= 500) return "is-danger";
      return null;
    },
    getFirestoreRef() {
      const ref = collection(this.tokenRef, "calls");
      return this.buildFirestoreRef(ref);
    },
    afterDataLoad() {
      this.pageLoading = false;
    },
    tryGetParsedJSON(body) {
      try {
        return JSON.parse(body);
      } catch {
        return body;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .vjs-tree {
  .vjs-key,
  .vjs-value {
    word-break: break-all;
  }
}
</style>
